import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Main } from '../components/Main';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import { Secuence } from '../components/Secuence';
import { SkillBar } from '../components/SkillBar';
import ProfileImage from '../images/profile-img.webp';
import ElectricGuitar from '../images/electric-guitar.svg';
import Gaming from '../images/gaming.svg';
import Metal from '../images/metal.svg';
import Tv from '../images/tv.svg';
import Ai from '../images/ai.svg';

const styles = (theme) => ({
  root: {},
  box: {
    padding: [0, '20px', '20px'],
    width: '100%',
    display: 'block',
    border: '1px solid #fae147',
    textAlign: 'center',
    margin: '10px'
  },
  hobbies: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  hobbie: {
    width: '50%',
    border: '1px solid #fae147',
    padding: '20px',
    display: 'block',
    textAlign: 'center'
  },
  '@media (max-width: 600px)': {
    hobbies: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    hobbie: {
      width: '100%',
    }
  }
});




class About extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <article>
          <Secuence stagger>
            <Fader>
              <header>
                <h1 style={{ textAlign: 'center' }}>
                  <Text>About Me</Text>
                </h1>
                <img
                  src={ProfileImage}
                  alt='Spiros G.'
                  style={{
                    width: '250px',
                    borderRadius: '50%'
                  }}
                />
              </header>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  I'm Spiros G, a versatile professional skilled in front-end and
                  back-end development, graphics design, server administration,
                  and network management.
                </Text>
              </p>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  As a developer, I bring websites to life with captivating
                  designs and functional interfaces.I excel at creating intuitive
                  user experiences and implementing complex functionalities.
                </Text>
              </p>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  With a keen eye for aesthetics, I also specialize in graphics
                  design, ensuring visually appealing elements that align with
                  your brand.
                </Text>
              </p>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  I have hands - on experience in server and network
                  administration, ensuring smooth operations and security.
                </Text>
              </p>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  My expertise extends to building eCommerce websites using
                  platforms like WooCommerce and PrestaShop, delivering secure and
                  seamless online shopping experiences.
                </Text>
              </p>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  Additionally, I create custom websites tailored to your unique
                  needs, reflecting your brand identity and driving engagement.
                </Text>
              </p>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  I stay updated with the latest trends and technologies,
                  passionate about delivering top - notch results.
                </Text>
              </p>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  Thank you for considering my website.I look forward to working
                  with you and bringing your digital vision to life.
                </Text>
              </p>

              <h1 style={{ textAlign: 'center', marginTop: '100px' }}>
                <Text>Skills</Text>
              </h1>
              <SkillBar skills={this.skills} />
              <h1 style={{ textAlign: 'center', marginTop: '100px' }}>
                <Text>Hobbies</Text>
              </h1>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  In my free time, I indulge in a variety of hobbies that bring
                  me joy and relaxation. Let me share a bit about them with you:{' '}
                </Text>
              </p>
<div className={classes.hobbies}>
<div className={classes.hobbie}>
              <img
                src={Gaming}
                alt='Gaming'
                style={{
                  width: '80px',
                  height: 'auto',
                  padding: '5px 5px',
                  marginBottom: '10px'
                }}
              />
              <p style={{ textAlign: 'center' }}>
                <Text>// Gaming // </Text>
              </p>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  As a dedicated gamer, I enjoy exploring virtual worlds,
                  solving puzzles, and engaging in thrilling adventures. Video
                  games provide me with a unique form of entertainment and allow
                  me to unwind while immersing myself in captivating narratives
                  and challenging gameplay.
                </Text>
              </p>
</div>
<div className={classes.hobbie}>
              <img
                src={Metal}
                alt='Metal Music'
                style={{
                  width: '80px',
                  height: 'auto',
                  padding: '5px 5px',
                  marginBottom: '10px'
                }}
              />
              <p style={{ textAlign: 'center' }}>
                <Text>// Metal Music // </Text>
              </p>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  I have a deep appreciation for the power and intensity of
                  metal music. Attending concerts and festivals is an
                  exhilarating experience for me, as I get to witness talented
                  musicians perform live and connect with fellow metal
                  enthusiasts. The energy and camaraderie of these events are
                  truly invigorating.
                </Text>
              </p>
</div>
<div className={classes.hobbie}>
              <img
                src={Tv}
                alt='Anime and TV Series'
                style={{
                  width: '80px',
                  height: 'auto',
                  padding: '5px 5px',
                  marginBottom: '10px'
                }}
              />
              <p style={{ textAlign: 'center' }}>
                <Text>// Anime and TV Series // </Text>
              </p>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  Anime and TV series have a special place in my heart. I enjoy
                  diving into captivating storylines, exploring different
                  genres, and immersing myself in the artistry of animation.
                  From thought-provoking narratives to epic adventures, these
                  forms of entertainment offer a delightful escape from reality.
                </Text>
              </p>
</div>
<div className={classes.hobbie}>
              <img
                src={Ai}
                alt='Artificial Intelligence'
                style={{
                  width: '80px',
                  height: 'auto',
                  padding: '5px 5px',
                  marginBottom: '10px'
                }}
              />
              <p style={{ textAlign: 'center' }}>
                <Text>// Artificial Intelligence // </Text>
              </p>{' '}
              <p style={{ textAlign: 'center' }}>
                <Text>
                 I am fascinated by the capabilities and
                  potential of artificial intelligence. Exploring the latest
                  advancements, learning about machine learning algorithms, and
                  staying up to date with AI research. It allows me to stay at the forefront of
                  technological innovation and understand the evolving landscape
                  of AI.
                </Text>
              </p>
</div>
</div>
              <p style={{ textAlign: 'center' }}>
                <Text>
                  These hobbies not only provide me with relaxation and
                  enjoyment but also serve as a source of inspiration and
                  personal growth. They allow me to explore different worlds,
                  and stay connected to the things that
                  bring me happiness.
                </Text>
              </p>
            </Fader>
          </Secuence>
        </article>
      </Main>
    );
  }
}

export default withStyles(styles)(About);
