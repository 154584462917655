import { rgba } from 'polished';

const styles = theme => ({
  skillsBar: {
    padding: '0 30px',
    textAlign: 'left'
  },
  bar: {
    margin: '25px 0',
    position: 'relative'
  },
  info: {
    marginBottom: 8,
    color: '#ffffff'
  },
  progressLine: {
    position: 'relative',
    height: 10,
    width: '100%',
    background: rgba(43, 43, 43, 0.5),
    borderRadius: 10
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    background: '#fae147',
    borderRadius: '10px',
    width: '0%'
  },

  percent: {
    position: 'absolute',
    top: -30,
    right: 0,
    background: '#efefef17',
    fontSize: '0.8em',
    color: '#ffffff',
    borderRadius: '10%',
    padding: '1px 5px'
  },
  '@media (max-width: 700px)': {
    skillsBar: {
      width: '100%'
    },
    'skillsBar .bar .progressLine': {
      width: '100%'
    }
  }
});

export default styles;
