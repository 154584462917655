import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '../../tools/withStyles';
import styles from './SkillBar.styles';

const skills = [
  { name: 'HTML', percent: 100 },
  { name: 'CSS', percent: 100 },
  { name: 'JAVASCRIPT', percent: 90 },
  { name: 'PHP', percent: 90 },
  { name: 'WORDPRESS/CMS', percent: 100 },
  { name: 'PHOTOSHOP', percent: 100 }
];

class SkillBar extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  render () {
    const { classes } = this.props;

    return (
      <div className={classes.skillsBar} >
        {
          skills.map(skill => (
            <div className={classes.bar} key={skill.name} >
              <div className={classes.info} >
                <span>{ skill.name } </span>
              </div>
              <div className={classes.progressLine} >
                <span className={classes.percent}> { skill.percent } % </span>
                <div className={classes.progressBar} style={{ width: `${skill.percent}%` }} />
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}

export default withStyles(styles)(SkillBar);
